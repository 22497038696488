// https://ianknowles.com/checkoutthanks?token=767d6d19295192b6ccf550b1c50a122fd024b25eaa707f888228f2c824161ba637b2dc9f5d454e504b13280b4a3cbadfdda4443223bc74096c2c157a0fadc3f0c201f2698519ece739b5f118baf1e14d11e4ebd61b43ae45cfc72f8787ff6d10aee6293d27b18e16f9df5be8c5513d5db802ca79c4030bf7b823a7c02e0758b36fb7d1945d010a40194f7c4b444bcfeb3783e0cf4324
// https://ianknowles.com/thanks?data=eyJvcmRlcnMiOlt7InByb2R1Y3QiOnsicHJvZHVjdF9pZCI6MTAwMCwidGl0bGUiOiJCbGFja2JveCBJSSBBdWRpbyBNYXN0ZXJpbmciLCJ0eXBlIjoiUFJPR1JBTSIsInBlcm1hbGluayI6ImJsYWNrYm94IiwiY29kZSI6IklLX0JMQUNLQk9YIiwibGFiZWwiOiJJYW4gS25vd2xlcyIsImRvd25sb2FkcyI6eyJPU1giOiJodHRwczovL2lhbmtub3dsZXMuYmxvYi5jb3JlLndpbmRvd3MubmV0L2Rpc3RyaWJ1dGlvbi9CbGFja2JveC4yLjEuNS5kbWciLCJXaW5YODYiOm51bGwsIldpblg2NCI6Imh0dHBzOi8vaWFua25vd2xlcy5ibG9iLmNvcmUud2luZG93cy5uZXQvZGlzdHJpYnV0aW9uL0JsYWNrYm94LjIuMS41LnppcCJ9fSwibGljZW5zZSI6Ijc4RTcyRjU2RjZFNEQ0NjM0OUE5MEQ2MTMwQjAxMjA3QkJCNzIxQTlBMTBFQ0YzMjRGQjU4Q0I0QTNFQzA5OUItNjk2MTZFNDA2OTYxNkU2QjZFNkY3NzZDNjU3MzJFNjM2RjZEIn1dfQ==

import * as React from "react"
import { Layout } from "../components/layout"
import { Helmet } from 'react-helmet'
import base64 from 'react-native-base64'

const isBrowser = typeof window !== "undefined"

const Title = "Thanks - Confirmation";
const Description = "Thank you!";
const licenseStyle = {
  padding:'0.25em',
  background:'#DDD'
}

const BlackboxLicense = (data) => {
  const order = data.order;
  if (order.product.code === "IK_BLACKBOX"
  || order.product.code === "IK_BLACKBOX_TEST"
  || order.product.code === "PRD_NAMMICK_BLACKBOX"
  || order.product.code === "PRD_NAMMICK_BLACKBOX_AMS") {
    return (
      <div>
        <p>
          License Key: {order.product.title}<br />
          <span className="productKey" style={licenseStyle}>{order.license}</span>
        </p>
      </div>
    )
  }
  return'';
}

const SummitLicense = (data) => {
  const order = data.order;
  if (order.product.code === "IK_PLUGIN_SUMMIT") {
    return (
      <div>
        <p>
          License Key: {order.product.title}<br />
          <span className="productKey" style={licenseStyle}>{order.license}</span>
        </p>
      </div>
    )
  }
  return'';
}

class ThanksPage extends React.Component {
  constructor(props) {
    super(props);

    if(isBrowser) {
      const urlParams = new URLSearchParams(props.location.search);
      const data = urlParams.get('data');
      var obj = JSON.parse(base64.decode(data)); 
      const orders = obj.orders;    
      this.state = {orders};
    }
  }
  render() {
    if(!isBrowser) {
      return '';
    }
    return (
      <Layout>
        <Helmet defer={false}>
          <title>{Title}</title>
          <meta name="description" content={Description} />
          <meta name="robots" content="NOINDEX, NOFOLLOW" />
        </Helmet>
        <section className="dark content-section">
          <div className="container">
            <h1>Thanks - Confirmation</h1>
          </div>
        </section>
        <section className="light content-section">
          <div className="container">
            {
              this.state.orders.map((order, i) => {
                return(
                  <div key={'order_' + i.toString()}>
                    <BlackboxLicense order={order} /> 
                    <SummitLicense order={order} />        
                  </div>
                )
              })
            }
          </div>
        </section>
      </Layout>
    )
  }
}

export default ThanksPage